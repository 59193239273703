import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState, // Safely ignore, we will only ever use writeState within this function
  readState,
} from "@/__main__/app-state.mjs";
import {
  getCollectionByEntryId,
  getEntriesByCanonical,
  getEntriesByTags,
  getEntry,
} from "@/shared/contentful-fetches.mjs";
import type { ContentNode } from "@/shared/RenderRichText.js";

// That has been enforced on Contentful (kind of, they can't do complex regexp like positive lookahead)
const RegExpCanonicalRequirement = /^(?=.*[-_])[a-z\d_-]+$/;

export default async function fetchData([id]: [string]): Promise<void> {
  // If the reg exp succeeds, the id variable is actually the canonical url, for the purposes of this function, it is represented as `id`
  if (RegExpCanonicalRequirement.test(id)) {
    const {
      data: {
        gameArticleCollection: { items },
      },
    } = await getEntriesByCanonical(id);
    // Note: Contentful can't do singular entry lookups and might return multiple entries in a collection if canonical urls contains the same keywords
    const item =
      Array.isArray(items) && items.find((i) => i.canonicalUrl === id); // This is why we do an exact equality check to ensure we retrieve the correct item
    if (!item) return;
    if (readState.contentful.canonical[item.canonicalUrl]) return;
    writeState.contentful.canonical[item.canonicalUrl] = item.sys.id;
    id = item.sys.id; // Reassign id for article entry fetching
  }
  await getEntry(id).then((entry) => {
    Promise.all([
      Promise.all(
        // We can't infer typings for this array because its rich text content (which can be handled down stream)
        getInlineEntryIds(entry.content as unknown as ContentNode).map(
          getCollectionByEntryId,
        ),
      ),
      // Side effect: Get related articles - Doesn't need to be async/await since this UI is at the bottom of the page
      getEntriesByTags(entry.tags.filter(Boolean), 4, "id_contains_all"),
    ]);
  });
}

// Utilities (exists only in this file, no need to chunk & waste requests)
function getInlineEntryIds(node: ContentNode): string[] {
  let ids: string[] = [];
  if (Array.isArray(node)) {
    node.forEach((item) => {
      ids = ids.concat(getInlineEntryIds(item));
    });
    return ids;
  }
  if (
    node &&
    node.nodeType === "embedded-entry-inline" &&
    node.data?.target?.sys?.id
  )
    ids.push(node.data.target.sys.id);
  if (node && node.content && Array.isArray(node.content)) {
    node.content.forEach((child) => {
      ids = ids.concat(getInlineEntryIds(child));
    });
  }
  return ids;
}
